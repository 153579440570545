.servicios{
    .servicios-container{
        margin-top: 180px;
        background-size: cover;
        background-color: #fff;
    background-size: 100%;
    background-position: center top;
    background-repeat: no-repeat;
        .container{
            display: flex;
            .title-servicios{
                display: flex;
                width: 100%;
                padding: 50px 0;
                align-items: center;

                .title-button-services{
                    font-weight: 500;
                    color: #000000;
                    width: 50%;
                    h3{
                        font-size: 2rem;
                        font-weight: 900;
                    }
                    button{
                        border: 1px solid;
                        border-radius: 5px;
                        background: transparent;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 1rem;
                        width: 50%;
                        margin-top: 30px;
                        font-size: 1.4rem;
                        padding: 20px 0;
                    }
                }
                .line-title{
                    margin: 20px 30px 20px 0;
                    height: 100% ;
                    width: 10px;
                    background-color: rgb(0, 153, 255);
                }
                p{
                    font-family: var(--secondary-font);
                    width: 50%;
                }
            }
        }
        
        .banner-servicios{
        width: 100%;
        background: rgb(103,125,203);
        background: linear-gradient(180deg, rgba(103,125,203,1) 0%, rgba(43,61,121,1) 93%);
        padding: 50px;
        font-size: 1.2rem;
        display: flex;
        gap: 1rem;
        justify-content: space-around;
        text-align: center;
        overflow: hidden;
        a{
        color: #fff !important;
        }
        h4{
            display: flex;
            align-items: center;
            gap: 0.4rem;
            font-size: 1.3rem;
            font-weight: normal;
        }
        
    }
    
    .service-box{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjZmZmIj48L3JlY3Q+CjxyZWN0IHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9IiNjY2MiPjwvcmVjdD4KPC9zdmc+");
        display: flex;
        .main-info-service-box{
            display: flex;
            flex-direction: column;
            gap: 2rem;
            width: 100%;
            align-items: center;
            justify-content: center;
            .main-box-title-service{
                display: flex;
                flex-direction: column;
                .title-service-box{
                    color: #0022ff;
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    font-size: 3.5rem;
                    padding: 90px 0 20px;
                }
                .p-service-box{
                    width: 75%;
                    font-family: var(--secondary-font);
                    font-size: 0.8rem;
                    padding: 20px 0;
                }
                hr{
                    border: 1px solid rgb(0, 153, 255);
                }
                .b-service-box{
                    color: rgb(64, 156, 227);
                    font-size: 0.9rem;
                    width: 75%;
                    font-family: var(--secondary-font);
                    text-transform: uppercase;
                }
            }

            .box-img-service-box{
                display: flex;      
                flex-direction: column;          
                .img-box-text{
                    align-items: center;
                    display: flex;
                    gap: 2rem;
                    img{
                        width: 50%;
                    }
                    .title-description-img-services{
                        display: flex;
                        .line-title{
                            width: 2px;
                            height: 100%x;
                            background-color: rgb(0, 153, 255);
                        }
                        h4{
                            font-size: 3rem;
                            padding: 20px 0 0 20px;
                            color: #0022ff;
                        }
                        hr{
                            margin: 20px 50px 20px 20px;
                        }
                        p{
                            font-family: var(--secondary-font);
                            margin: 20px;
                        }
                    }
                        
                }

                .contact-button-and-p{
                    text-transform: uppercase;
                    margin: 50px 0;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    align-items: center;
                    width: 100%;
                    align-content: center;
                    justify-content: center;
                    p{
                        margin: 0 100px;
                    }

                    button{
                        border: 2px solid rgb(0, 153, 255);
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        background-color: #fff;
                        justify-content: center;
                        gap: 1rem;
                        color: rgb(0, 153, 255);
                        width: 100%;
                        font-size: 1.5rem;
                        padding: 20px 30px;
                    }
                }
            }
        }
    }
}

}

@media (max-width: 767px) {
    .servicios {
      .servicios-container {
        margin-top: 120px;
        background-size: cover;
        background-size: 100%;
        background-position: center top;
        background-repeat: no-repeat;
        .container {
          display: flex;
          flex-direction: column;
          .title-servicios {
            flex-wrap: wrap;
            width: 100%;
            padding: 30px 0;
            .title-button-services {
              width: 100%;
              h3 {
                font-size: 2rem;
              }
              button {
                width: 100%;
                margin-top: 20px;
                font-size: 1.2rem;
                padding: 15px 0;
              }
            }
            .line-title {
              display: none;
            }
            p {
              width: 100%;
              text-align: center;
              padding: 20px 0;
            }
          }
        }
        .banner-servicios {
          padding: 30px;
          font-size: 1rem;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          justify-content: space-around;
          text-align: center;
          overflow: hidden;
          h4 {
            font-size: 1.2rem;
            text-align: start;
          }
        }
        .service-box {
            background-image: none;
          background-color: rgba(43,61,121,1);
          .main-info-service-box {
            width: 100%;
            align-items: center;
            .main-box-title-service {
              .title-service-box {
                color: #fff !important;

                font-size: 2.5rem;
                padding: 50px 0 10px;
              }
              .p-service-box {
                width: 100%;
                font-size: 0.9rem;
                padding: 10px 0;
                color: #fff !important;

              }
              hr {
                display: none;
              }
              .b-service-box {
                color: #ececec;
                font-size: 0.9rem;
                width: 100%;
              }
            }
            .box-img-service-box {
              .img-box-text {
                flex-direction: column;
                img {
                  width: 100%;
                }
                .title-description-img-services {
                  flex-direction: column;
                  color: #fff !important;
                  h4 {
                  color: #fff !important;

                    font-size: 2.5rem;
                    padding: 20px 0 0;
                  }
                  hr {
                    margin: 20px 0;
                  }

                  p {
                    margin: 20px 0;
                  }
                }
              }
              .contact-button-and-p {
                width: 100%;
                
                p {
                    color: #fff !important;
                  margin: 0;
                  font-size: 1.4rem;
                }
                
                button {
                  width: 100%;
                  margin-top: 20px;
                  font-size: 1.2rem;
                  padding: 20px 15px;
                }
              }
            }
          }
        }
      }
    }
  }
  