.footer {
  display: flex;
  flex-direction: column;
  color: #000;
  background: #fff;
  padding: 30px 0;
}
.footer .upper-footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.footer .upper-footer-content .links-footer {
  margin: 20px 20px 20px 0;
  font-size: 1.2rem;
  display: flex;
  gap: 0.5rem;
}
.footer .upper-footer-content .links-footer p {
  color: #000 !important;
}
.footer .upper-footer-content .socials-footer {
  text-decoration: none;
  gap: 1rem;
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
}
.footer .upper-footer-content .socials-footer a {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  background-color: #3e3e3e;
  color: #ffffff !important;
}
.footer .bottom-footer-content {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.footer .bottom-footer-content .oneCreativo-footer a {
  text-decoration: none;
  color: #000 !important;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
.footer .bottom-footer-content .oneCreativo-footer a span {
  font-size: 0.8rem;
}
.footer .bottom-footer-content .oneCreativo-footer a img {
  width: 40px;
}
@media (max-width: 768px) {
  .footer {
    padding: 20px 0;
  }
  .footer .upper-footer-content {
    flex-direction: column;
  }
  .footer .upper-footer-content .links-footer {
    text-align: center;
    margin: 10px 0;
  }
  .footer .upper-footer-content .socials-footer {
    margin: 10px 0;
  }
  .footer .bottom-footer-content {
    flex-direction: column;
    margin-top: 20px;
  }
  .footer .bottom-footer-content .oneCreativo-footer {
    margin-bottom: 20px;
  }
}
@media (max-width: 480px) {
  .footer {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  .footer .upper-footer-content {
    flex-direction: column;
  }
  .footer .upper-footer-content .links-footer {
    flex-direction: column;
    margin: 10px 0;
  }
  .footer .upper-footer-content .socials-footer {
    margin-top: 10px;
  }
  .footer .bottom-footer-content {
    flex-direction: column;
    margin-top: 20px;
  }
  .footer .bottom-footer-content .oneCreativo-footer {
    margin-bottom: 20px;
  }
}/*# sourceMappingURL=footerStyles.css.map */