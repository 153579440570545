.accordion {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
    .accordion-item {
      .accordion-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem;
        border: 1px solid #ccc;
        cursor: pointer;
        transition: all 0.2s ease;
        border-radius: 12px;
        
        &:hover {
            background-color: #f7f7f7;
        }
        .title-desc-accordion{
          h3 {
            margin: 0;
            font-size: 1.3rem;
            margin-bottom: 7px;
            font-weight: bold;
        }
        
        p {
            margin: 0;
            font-size: 1.1rem;
            color: #666;
        }
        }
            
        }
  
        .accordion-button {
          font-size: 1.5rem;
          line-height: 0.5;
          transform: rotate(0);
          transition: all 0.2s ease;
        }
  
        &.active .accordion-button {
          transform: rotate(45deg);
        }
      }
  
      .accordion-content {
        border: 1px solid #ccc;
        border-top: none;
        font-size: 0.9rem;
        color: #666;
        display: flex;
        gap: 3rem;
        .description-products{
          background: rgb(233, 233, 233);
          color: #000000;
          padding: 2rem;
          width: 70%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 1rem;
          h3{
            color: rgb(62, 62, 206);
            font-size: 2rem;
          }
        }
        .accordion-images{
          justify-content: center;
          gap: 3rem;
          display: flex;
          width: 100%;
          img {
            margin: 20px 0;
            -webkit-box-shadow: 10px 10px 24px 0px rgba(0,0,0,0.75);
            -moz-box-shadow: 10px 10px 24px 0px rgba(0,0,0,0.75);
            box-shadow: 10px 10px 24px 0px rgba(0,0,0,0.75);
            object-fit: cover;
            width: 25%;
            border-radius: 5px;
        }
        }
  
        
      }
    }

    @media screen and (max-width: 768px) {
      .accordion {
        .accordion-item {
          .accordion-header {
            .title-desc-accordion {
              h3 {
                font-size: 1.6rem;
              }
              p {
                font-size: 1rem;
              }
            }
          }
          .accordion-content {
            flex-direction: column;
            .accordion-images {
              flex-direction: column;
              justify-content: center;
              img {
                width: 80%;
                height: auto;
              }
            }
          }
        }
      }
    }
    
    @media screen and (max-width: 576px) {
      .accordion {
        .accordion-item {
          .accordion-header {
            padding: 1.5rem;
            .title-desc-accordion {
              h3 {
                font-size: 1.4rem;
              }
              p {
                font-size: 0.9rem;
              }
            }
          }
          .accordion-content {
            align-items: center;
            .description-products {
              padding: 1.5rem;
              h3 {
                font-size: 2rem;
              }
            }
            .accordion-images {
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }