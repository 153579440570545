$primary-color: #0077FF;
$font-size: 16px;

// Mixin para centrar elementos horizontalmente y verticalmente
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  flex-direction: column;
  color: #000;
  background: #fff;
  padding: 30px 0;

  .upper-footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    .links-footer {
      margin: 20px 20px 20px 0;
      font-size: 1.2rem;
      display: flex;
      gap: 0.5rem;

      p {
        color: #000 !important;
      }
    }

    .socials-footer {
      text-decoration: none;
      gap: 1rem;
      font-size: 1.5rem;
      display: flex;
      flex-direction: row;

      a {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 50%;
        background-color: #3e3e3e;
        color: #ffffff !important;
      }
    }
  }

  .bottom-footer-content {
    display: flex;
    justify-content: center;
    margin: 20px 0;

    .oneCreativo-footer {
      a {
        text-decoration: none;
        color: #000 !important;
        display: flex;
        align-items: center;
        gap: 0.7rem;

        span {
          font-size: 0.8rem;
        }

        img {
          width: 40px;
        }
      }
    }
  }

  // Media queries
  @media (max-width: 768px) {
    padding: 20px 0;

    .upper-footer-content {
      flex-direction: column;

      .links-footer {
        text-align: center;
        margin: 10px 0;
      }

      .socials-footer {
        margin: 10px 0;
      }
    }

    .bottom-footer-content {
      flex-direction: column;
      margin-top: 20px;

      .oneCreativo-footer {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    padding: 20px;

    .upper-footer-content {
      flex-direction: column;

      .links-footer {
        flex-direction: column;
        margin: 10px 0;
      }

      .socials-footer {
        margin-top: 10px;
      }
    }

    .bottom-footer-content {
      flex-direction: column;
      margin-top: 20px;

      .oneCreativo-footer {
        margin-bottom: 20px;
      }
    }
  }
}