.contacto {
  min-height: 80vh;
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjZmZmIj48L3JlY3Q+CjxyZWN0IHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9IiNjY2MiPjwvcmVjdD4KPC9zdmc+");
}
.contacto .contact-box-container {
  padding: 30px;
}
.contacto .contact-box-container .title-contact {
  margin: 50px 0 30px;
}
.contacto .contact-box-container .title-contact h3 {
  font-size: 2rem;
  color: #000000;
}
.contacto .contact-box-container .title-contact p {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  gap: 0.5rem;
  padding: 10px 0;
}
.contacto .contact-box-container .contact-container {
  width: auto;
  display: flex;
  width: 100% x;
  gap: 3rem;
}
.contacto .contact-box-container .contact-container .form-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 1rem;
}
.contacto .contact-box-container .contact-container .form-container input {
  width: 400px;
  padding: 20px;
  border-radius: 5px;
  border: none;
  background-color: #EFEFEF;
}
.contacto .contact-box-container .contact-container .form-container textarea {
  resize: none;
  padding: 20px;
  border-radius: 5px;
  border: none;
  background-color: #EFEFEF;
  width: 400px;
  height: 150px;
}
.contacto .contact-box-container .contact-container .form-container .button-send-form {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-weight: 900;
  color: #06458d;
  width: 400px;
  padding: 20px;
  background-color: transparent;
  border: 2px solid #06458d;
  font-size: 1.5rem;
}
.contacto .contact-box-container .contact-data {
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  gap: 2rem;
}
.contacto .contact-box-container .contact-data hr {
  margin: 10px 0;
}
.contacto .contact-box-container .contact-data h3 {
  text-transform: uppercase;
  font-size: 3rem;
  color: #06458d;
}

@media only screen and (max-width: 767px) {
  .contacto {
    margin-top: 100px;
  }
  .contacto .contact-box-container {
    padding: 15px;
  }
  .contacto .contact-box-container .title-contact {
    margin: 30px 0 20px;
  }
  .contacto .contact-box-container .title-contact h3 {
    font-size: 2rem;
  }
  .contacto .contact-box-container .title-contact p {
    font-size: 1rem;
    padding: 5px 0;
  }
  .contacto .contact-box-container .contact-container {
    flex-direction: column;
    gap: 2rem;
  }
  .contacto .contact-box-container .contact-container .form-container {
    width: 100%;
  }
  .contacto .contact-box-container .contact-container .form-container input {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
  }
  .contacto .contact-box-container .contact-container .form-container textarea {
    width: 100%;
    height: 100px;
    padding: 15px;
    font-size: 1rem;
  }
  .contacto .contact-box-container .contact-container .form-container .button-send-form {
    width: 100%;
    padding: 15px;
    font-size: 1.2rem;
  }
  .contacto .contact-box-container .contact-container .contact-data {
    padding: 0;
  }
  .contacto .contact-box-container .contact-container .contact-data iframe {
    width: 100%;
    height: auto;
  }
  .contacto .contact-box-container .contact-container .contact-data h3 {
    font-size: 2rem;
  }
}/*# sourceMappingURL=contacto.css.map */