.main-container{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: #fff;
    text-align: center;
    .main-title{
        margin-top: 70px;
        font-weight: 200;
        font-size: 6em;
        text-transform: uppercase;
        line-height: 1em;
        :hover{
            transition: var(--transition);
            color: #0051ff;
        }
    }
    .line-main{
        width: 20%;
        margin: 10px 0 20px;
        border-color:#91d8f7;
    }
    .info-p-main{
        font-size: 1.1rem;
        width: 40%;
        margin-bottom: 30px;
    }
    .button-container-main{
        display: flex;
        gap: 1rem;
        .btn-main{
            text-transform: uppercase;
            font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            gap: 1rem;
            padding: 0 40px 0 0;
            display: flex;
            color: #fff;
            background: transparent;
            margin: 0 1.25em 0 0;
            vertical-align: middle;
            align-items: center;
            border: 2px solid #ffffff;
            .arrow-btn-main-left{
                color: #000;
                width: 50px;
                height: 40px;
                background-color: #91d8f7;
                position: relative;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
            }
            .arrow-btn-main-right{
                color: #000;
                width: 50px;
                height: 40px;
                background-color: #0051ff;
                position: relative;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
            }
        }
    }
}

.banner-main{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    color: #fff;
    height: 100px;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
}

.info-main-container{
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjZmZmIj48L3JlY3Q+CjxyZWN0IHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9IiNjY2MiPjwvcmVjdD4KPC9zdmc+");
    width: 100%;
    .text-info-main{
        text-align: center;
        padding: 20px 0;
        .title-info{
            font-size: 2.5rem;
            padding: 10px;
        }
        .subtitle-info{
            font-size: 1.2rem;
            color: #0051ff !important;
            text-decoration: none;
            .icon-info{
                position: relative;
                top: 4px;
            }
        }
    }
    .boxes-info{
        display: flex;
        justify-content: center;
        align-items: center;
        .box-us{
            font-family: var(--secondary-font);
            color: #fff;
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            text-align: center;
            justify-content: center;
            background: rgb(2,0,36);
            background: linear-gradient(152deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
            width: 25%;
            height: 300px;
            padding: 30px;
            margin: 30px 15px;
        }
    }
}

.btn-presupuesto{
    text-transform: uppercase;
            font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            gap: 1rem;
            padding: 20px 40px;
            display: flex;
            color: #000000;
            background: #91d8f7;
            margin: 0 1.25em 0 0;
            vertical-align: middle;
            align-items: center;
            border: 2px solid #ffffff;
}

.prod-info-main-container{
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
    .prod-info-main{
        display: flex;
        max-width: 100%;
        align-items: center;
        color: #fff;
        padding: 20px 0;
        .prod-info-main-text{
        padding: 30px;
        h3{
            display: flex;
            align-items: center;
            font-size: 2rem;
            text-transform: uppercase;
            gap: 20px;
            margin: 20px 0;
            .line-title{
                height: 30px;
                width: 5px;
                background-color: #00fff2;
            }
        }
        p{
            font-size: 0.9rem;
            margin-bottom: 30px;
        }
        .button-info-main-text{
            width: 25%;
            text-transform: uppercase;
            font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            gap: 1rem;
            padding: 20px;
            display: flex;
            color: #fff;
            background: transparent;
            margin: 0 1.25em 0 0;
            vertical-align: middle;
            align-items: center;
            border: 2px solid #ffffff;
        }
    }
    img{
        -webkit-box-shadow: 14px 14px 20px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 14px 14px 20px 0px rgba(0,0,0,0.75);
        box-shadow: 14px 14px 20px 0px rgba(0,0,0,0.75);
        border-radius: 15px;
        margin: 30px;
        width: 30%;
    }
}
}

.banner-main-bottom{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 900;
    color: #000000;
    height: 100px;
    background: rgb(2,0,36);
    background: #fff;
    .title-banner-bottom{
        display: flex;
        gap: 1rem;
        align-items: center;
        vertical-align: center;    
    }
}
 

@media screen and (max-width: 768px) {
    .main-container {
    min-height: auto;
    margin-top: 100px;
    max-width: 100vw;
    .main-title {
    font-size: 1rem !important;
    }
    .line-main {
    width: 30%;
    }
    .info-p-main {
    font-size: 1rem;
    width: 80%;
    margin-bottom: 20px;
    }
    .button-container-main {
        margin: 20px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    .btn-main {
        width: 100% !important;
    margin: 0 0.5em 1em;
    padding: 0 20px 0 0;
    font-size: 0.8rem;
    }
    }
    }
    .banner-main {
    font-size: 2.5rem;
    }
    .prod-info-main{
        flex-direction: column;
        .button-info-main-text{
            width: 100% !important;
        }
        h3{
            font-size: 1.2rem !important;
        }
        img{
            width: 80% !important;
            margin: 0;
            padding: 0;
        }
    }
    .info-main-container {
    .text-info-main {
    .title-info {
    font-size: 2rem;
    }
    .subtitle-info {
    font-size: 1rem;
    }
    }
    .boxes-info {
    flex-wrap: wrap;
    .box-us {
    width: 80%;
    height: 200px;
    margin: 20px 0;
    font-size: 1.5rem;
    padding: 20px;
    
    }
    }
    }
    .btn-presupuesto {
    padding: 10px 20px;
    }
    }