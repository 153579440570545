.productos {
  min-height: 80vh;
  margin-top: 150px;
  display: flex;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjZmZmIj48L3JlY3Q+CjxyZWN0IHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9IiNjY2MiPjwvcmVjdD4KPC9zdmc+");
}
.productos .pdfsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.productos .pdfsContainer h3 {
  font-size: 3rem;
  padding: 20px 0;
  color: #043d8d;
}
.productos .pdfsContainer .imgModels {
  display: flex;
  justify-content: center;
  gap: 5rem;
  align-items: center;
  margin: 30px 0;
}
.productos .pdfsContainer .imgModels .img-threeD-p {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 2rem;
}
.productos .pdfsContainer .imgModels .img-threeD-p p {
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 2rem;
  color: #0d56bc !important;
}
.productos .pdfsContainer .imgModels .img-threeD-p img {
  height: 300px !important;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 12px;
  width: 100%;
  box-shadow: 10px 11px 5px 0px rgba(0, 0, 0, 0.75);
}
.productos .title-prods {
  font-size: 1.8rem;
  color: #000000;
  margin: 30px 0;
  text-decoration: underline;
}
.productos .banner-principal-prods {
  width: 100%;
  height: 250px;
  background-size: cover;
  background-size: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  position: relative;
  background-image: url(../../assets/img/met1.jpg);
}
.productos .productos-container {
  grid-gap: 20px;
  margin-top: 50px;
}
.productos .productos-container .header-accordion-item {
  background-color: #dedede !important;
}
.productos .productos-container .header-accordion-item img {
  background-position: center;
  background-size: cover;
  width: 150px;
  height: 150px;
  border-radius: 12px;
}
.productos .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.productos .modal-overlay .modal {
  max-width: 500px;
  width: 80%;
  background-color: #fff;
  border: 1px solid #0d56bc;
  box-shadow: 10px 11px 5px 0px rgba(0, 0, 0, 0.75);
}
.productos .modal-overlay .modal-header {
  border-bottom: 1px solid #dedede;
}
.productos .modal-overlay .modal-title {
  margin: 0;
  font-size: 1.25rem;
  padding: 1rem;
  text-align: left;
}
.productos .modal-overlay .modal-content {
  text-align: left;
  padding: 0.5rem;
}
.productos .modal-overlay .modal-content img {
  max-width: 100%;
}
.productos .modal-overlay .modal-footer {
  padding: 1rem;
  border-top: 1px solid #dedede;
  text-align: right;
}
.productos .modal-overlay .modal-button {
  font-size: 1rem;
  font-weight: 700;
  color: #f94144;
  background: #fff;
  padding: 0;
  border-radius: 0;
  border: 0;
  margin-right: 1rem;
}

@media only screen and (max-width: 576px) {
  .productos {
    margin-top: 100px;
  }
  .productos .pdfsContainer .imgModels {
    flex-direction: column;
  }
  .productos .title-prods {
    font-size: 2.5rem;
  }
  .productos .banner-principal-prods {
    height: 100px !important;
  }
}/*# sourceMappingURL=productos.css.map */