.nav-container{
    position: fixed;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    gap: 3rem;
    width: 100%;
    align-items: center;
    transition: all 1s ease-out;
    z-index: 9999999;
    .logo-nav{
        background: #fff;
        padding: 10px 20px ;
        border-radius: 12px;
        width: 220px;
    }
    .hamburguerMenu{
        display: none;
    }
    .list-tab{
        display: flex;
        list-style: none;
        gap: 0.3rem;
        .tab-nav{
            
            transform: none;
            border: 0.1px solid #cccccc27;
            color: #fff;
            font-size: 0.9rem;
            padding: 20px 40px;
            background: rgba(0, 0, 0, 0.349);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(1.4px);
            -webkit-backdrop-filter: blur(1.4px);
            .dropdown-services{
                position: absolute
            }
            &::hover{
                color: #ff3c3c;
                transition: var(--transition);
            }
            .icon-nav{
                position: relative;
                top: 3px;
            }
        }
        .tab-special{
            color: #000;
            background-color: #91d8f7;
        }
        .list-servicios-ul{
            margin-top: 30px;
            width: auto;
            background-color: #91d8f7;
            list-style: none;
            height: 300px;
            display: flex;
            justify-content: center;
            align-content: start;
            flex-direction: column;
            text-align: start;
            .list-item-servicios{
                font-weight: 900;
                font-size: 0.8rem;
                color: #000000;
                background-color: #91d8f7;
                padding: 10px;
            }
        }
    }
}

.scrolled{
    background-color: white;
 }

  
  // Estilos para pantallas menores a 768px
  @media screen and (max-width: 767px) {
    .nav-container{
        background-color: #fff;
        padding: 10px;
        margin: 0;
        .hamburguerMenu{
            display: block !important;
            color: #000 !important;
            font-size: 2rem;
        }
        .list-tab{
            flex-direction: column;
            display: none;
            position: absolute;
            top: 95px;
            width: 100%;
            transition: all 0.5 ease;
            .tab-nav{
            background: rgba(31, 31, 31, 0.966);
        }
        .tab-special{
            color: #ffffff;
            background-color: #91d8f7 !important;
        }
            .list-servicios-ul{
                display: none;
            }
        }
        .open{
            display: block !important;
        }
    }
  }