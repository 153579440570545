html{
    background-image: url(./assets/img/fondo-desktop.jpeg) !important;
    background-size: cover;
    background-size: 100%;
    background-position: center top;
    background-repeat: no-repeat;
    position: relative;
    background-color: rgba(27,57,109,.95);
    background-blend-mode: multiply;
}


@media screen and (max-width: 767px) {
    html{
        background-image: url(./assets/img/fondo-mobile.jpeg);
        background-size: 350%;
    }

}
