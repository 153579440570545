.whatsapp-button{
    background-color: #00ff04;
    position: fixed;
    bottom: 30px;
    right: 30px;
    border-radius: 50px;
}

.whatsapp-container{
    display: flex;
    align-items: center;
    color: #fff !important;
    padding: 15px;
    font-size: 2.5rem;
}

@media screen and (max-width: 767px) {
  .whatsapp-button{
    display: none;
  }
  .whatsapp-container{
    display: none;
  }
}