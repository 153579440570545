.contacto{
    min-height: 80vh;
    margin-top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjZmZmIj48L3JlY3Q+CjxyZWN0IHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9IiNjY2MiPjwvcmVjdD4KPC9zdmc+");
    .contact-box-container{
        padding: 30px;
        .title-contact{
            margin: 50px 0 30px;
            h3{
                font-size: 2rem;
                color: #000000;
            }
            p{
                display: flex;
                align-items: center;
                font-size: 1.1rem;
                gap: 0.5rem;
                padding: 10px 0;
            }
        }
        .contact-container{
            width: auto;
            display: flex;
            width: 100%x;
            gap: 3rem;
            .form-container{
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: start;
                gap: 1rem;

                input{
                    width: 400px;
                    padding: 20px;
                    border-radius: 5px;
                    border: none;
                    background-color: #EFEFEF;
                }
                textarea{
                    resize: none;
                    padding: 20px;
                    border-radius: 5px;
                    border: none;
                    background-color: #EFEFEF;
                    width: 400px;
                    height: 150px;
                }

                .button-send-form{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                    font-weight: 900;
                    color: #06458d;
                    width: 400px;
                    padding: 20px;
                    background-color: transparent;
                    border: 2px solid #06458d;
                    font-size: 1.5rem;
                }
            }
        }

        .contact-data{
            display: flex;
            flex-direction: column;
            padding: 0 50px;
            gap: 2rem;
            hr{
                margin: 10px 0;

            }
            h3{
                text-transform: uppercase;
                font-size: 3rem;
                color: #06458d;
            }
        }
    }

}

@media only screen and (max-width: 767px) {
    .contacto {
      margin-top: 100px;
      .contact-box-container {
        padding: 15px;
        .title-contact {
          margin: 30px 0 20px;
          h3 {
            font-size: 2rem;
          }
          p {
            font-size: 1rem;
            padding: 5px 0;
          }
        }
        .contact-container {
          flex-direction: column;
          gap: 2rem;
          .form-container {
            width: 100%;
            input {
              width: 100%;
              padding: 15px;
              font-size: 1rem;
            }
            textarea {
              width: 100%;
              height: 100px;
              padding: 15px;
              font-size: 1rem;
            }
            .button-send-form {
              width: 100%;
              padding: 15px;
              font-size: 1.2rem;
            }
          }
          .contact-data {
            padding: 0;
            iframe{
                width: 100%;
                height: auto;
            }
            h3 {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }