.nav-container {
  position: fixed;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  gap: 3rem;
  width: 100%;
  align-items: center;
  transition: all 1s ease-out;
  z-index: 9999999;
}
.nav-container .logo-nav {
  background: #fff;
  padding: 10px 20px;
  border-radius: 12px;
  width: 220px;
}
.nav-container .hamburguerMenu {
  display: none;
}
.nav-container .list-tab {
  display: flex;
  list-style: none;
  gap: 0.3rem;
}
.nav-container .list-tab .tab-nav {
  transform: none;
  border: 0.1px solid rgba(204, 204, 204, 0.1529411765);
  color: #fff;
  font-size: 0.9rem;
  padding: 20px 40px;
  background: rgba(0, 0, 0, 0.349);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.4px);
  -webkit-backdrop-filter: blur(1.4px);
}
.nav-container .list-tab .tab-nav .dropdown-services {
  position: absolute;
}
.nav-container .list-tab .tab-nav::hover {
  color: #ff3c3c;
  transition: var(--transition);
}
.nav-container .list-tab .tab-nav .icon-nav {
  position: relative;
  top: 3px;
}
.nav-container .list-tab .tab-special {
  color: #000;
  background-color: #91d8f7;
}
.nav-container .list-tab .list-servicios-ul {
  margin-top: 30px;
  width: auto;
  background-color: #91d8f7;
  list-style: none;
  height: 300px;
  display: flex;
  justify-content: center;
  align-content: start;
  flex-direction: column;
  text-align: start;
}
.nav-container .list-tab .list-servicios-ul .list-item-servicios {
  font-weight: 900;
  font-size: 0.8rem;
  color: #000000;
  background-color: #91d8f7;
  padding: 10px;
}

.scrolled {
  background-color: white;
}

@media screen and (max-width: 767px) {
  .nav-container {
    background-color: #fff;
    padding: 10px;
    margin: 0;
  }
  .nav-container .hamburguerMenu {
    display: block !important;
    color: #000 !important;
    font-size: 2rem;
  }
  .nav-container .list-tab {
    flex-direction: column;
    display: none;
    position: absolute;
    top: 95px;
    width: 100%;
    transition: all 0.5 ease;
  }
  .nav-container .list-tab .tab-nav {
    background: rgba(31, 31, 31, 0.966);
  }
  .nav-container .list-tab .tab-special {
    color: #ffffff;
    background-color: #91d8f7 !important;
  }
  .nav-container .list-tab .list-servicios-ul {
    display: none;
  }
  .nav-container .open {
    display: block !important;
  }
}/*# sourceMappingURL=navbar.css.map */