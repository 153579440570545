.main-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #fff;
  text-align: center;
}
.main-container .main-title {
  margin-top: 70px;
  font-weight: 200;
  font-size: 6em;
  text-transform: uppercase;
  line-height: 1em;
}
.main-container .main-title :hover {
  transition: var(--transition);
  color: #0051ff;
}
.main-container .line-main {
  width: 20%;
  margin: 10px 0 20px;
  border-color: #91d8f7;
}
.main-container .info-p-main {
  font-size: 1.1rem;
  width: 40%;
  margin-bottom: 30px;
}
.main-container .button-container-main {
  display: flex;
  gap: 1rem;
}
.main-container .button-container-main .btn-main {
  text-transform: uppercase;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  gap: 1rem;
  padding: 0 40px 0 0;
  display: flex;
  color: #fff;
  background: transparent;
  margin: 0 1.25em 0 0;
  vertical-align: middle;
  align-items: center;
  border: 2px solid #ffffff;
}
.main-container .button-container-main .btn-main .arrow-btn-main-left {
  color: #000;
  width: 50px;
  height: 40px;
  background-color: #91d8f7;
  position: relative;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}
.main-container .button-container-main .btn-main .arrow-btn-main-right {
  color: #000;
  width: 50px;
  height: 40px;
  background-color: #0051ff;
  position: relative;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.banner-main {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  color: #fff;
  height: 100px;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, rgb(2, 0, 36) 0%, rgb(9, 9, 121) 35%, rgb(0, 212, 255) 100%);
}

.info-main-container {
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjZmZmIj48L3JlY3Q+CjxyZWN0IHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9IiNjY2MiPjwvcmVjdD4KPC9zdmc+");
  width: 100%;
}
.info-main-container .text-info-main {
  text-align: center;
  padding: 20px 0;
}
.info-main-container .text-info-main .title-info {
  font-size: 2.5rem;
  padding: 10px;
}
.info-main-container .text-info-main .subtitle-info {
  font-size: 1.2rem;
  color: #0051ff !important;
  text-decoration: none;
}
.info-main-container .text-info-main .subtitle-info .icon-info {
  position: relative;
  top: 4px;
}
.info-main-container .boxes-info {
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-main-container .boxes-info .box-us {
  font-family: var(--secondary-font);
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  text-align: center;
  justify-content: center;
  background: rgb(2, 0, 36);
  background: linear-gradient(152deg, rgb(2, 0, 36) 0%, rgb(9, 9, 121) 35%, rgb(0, 212, 255) 100%);
  width: 25%;
  height: 300px;
  padding: 30px;
  margin: 30px 15px;
}

.btn-presupuesto {
  text-transform: uppercase;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  gap: 1rem;
  padding: 20px 40px;
  display: flex;
  color: #000000;
  background: #91d8f7;
  margin: 0 1.25em 0 0;
  vertical-align: middle;
  align-items: center;
  border: 2px solid #ffffff;
}

.prod-info-main-container {
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, rgb(2, 0, 36) 0%, rgb(9, 9, 121) 35%, rgb(0, 212, 255) 100%);
}
.prod-info-main-container .prod-info-main {
  display: flex;
  max-width: 100%;
  align-items: center;
  color: #fff;
  padding: 20px 0;
}
.prod-info-main-container .prod-info-main .prod-info-main-text {
  padding: 30px;
}
.prod-info-main-container .prod-info-main .prod-info-main-text h3 {
  display: flex;
  align-items: center;
  font-size: 2rem;
  text-transform: uppercase;
  gap: 20px;
  margin: 20px 0;
}
.prod-info-main-container .prod-info-main .prod-info-main-text h3 .line-title {
  height: 30px;
  width: 5px;
  background-color: #00fff2;
}
.prod-info-main-container .prod-info-main .prod-info-main-text p {
  font-size: 0.9rem;
  margin-bottom: 30px;
}
.prod-info-main-container .prod-info-main .prod-info-main-text .button-info-main-text {
  width: 25%;
  text-transform: uppercase;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  gap: 1rem;
  padding: 20px;
  display: flex;
  color: #fff;
  background: transparent;
  margin: 0 1.25em 0 0;
  vertical-align: middle;
  align-items: center;
  border: 2px solid #ffffff;
}
.prod-info-main-container .prod-info-main img {
  box-shadow: 14px 14px 20px 0px rgba(0, 0, 0, 0.75);
  border-radius: 15px;
  margin: 30px;
  width: 30%;
}

.banner-main-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 900;
  color: #000000;
  height: 100px;
  background: rgb(2, 0, 36);
  background: #fff;
}
.banner-main-bottom .title-banner-bottom {
  display: flex;
  gap: 1rem;
  align-items: center;
  vertical-align: center;
}

@media screen and (max-width: 768px) {
  .main-container {
    min-height: auto;
    margin-top: 100px;
    max-width: 100vw;
  }
  .main-container .main-title {
    font-size: 1rem !important;
  }
  .main-container .line-main {
    width: 30%;
  }
  .main-container .info-p-main {
    font-size: 1rem;
    width: 80%;
    margin-bottom: 20px;
  }
  .main-container .button-container-main {
    margin: 20px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .main-container .button-container-main .btn-main {
    width: 100% !important;
    margin: 0 0.5em 1em;
    padding: 0 20px 0 0;
    font-size: 0.8rem;
  }
  .banner-main {
    font-size: 2.5rem;
  }
  .prod-info-main {
    flex-direction: column;
  }
  .prod-info-main .button-info-main-text {
    width: 100% !important;
  }
  .prod-info-main h3 {
    font-size: 1.2rem !important;
  }
  .prod-info-main img {
    width: 80% !important;
    margin: 0;
    padding: 0;
  }
  .info-main-container .text-info-main .title-info {
    font-size: 2rem;
  }
  .info-main-container .text-info-main .subtitle-info {
    font-size: 1rem;
  }
  .info-main-container .boxes-info {
    flex-wrap: wrap;
  }
  .info-main-container .boxes-info .box-us {
    width: 80%;
    height: 200px;
    margin: 20px 0;
    font-size: 1.5rem;
    padding: 20px;
  }
  .btn-presupuesto {
    padding: 10px 20px;
  }
}/*# sourceMappingURL=mainStyles.css.map */

.slider {
  display: grid;
  height: 107px;
  overflow: hidden;
  place-items: center;
  position: relative;
  background: #fff;
}

.slide-track {
  -webkit-animation: scroll 36s linear infinite;
  animation: scroll 36s linear infinite;
  display: flex;
  background: #fff;
}

@keyframes scroll{

  0%{
      transform: translateX(0);
  }

  100%{
      transform: translateX(calc(-282px * 11));
  }
}

.slides{
  width: 282px;
  display: flex;
  align-items: center;
  padding: 15px 25px;
}

.slides img{
  width: 100%;
}

.slider::before,.slider::after{
  background: linear-gradient(to right,rgba(255,255,255,1)0%,
   rgba(255,255,255,0)100%);
  content: "";
  height: 100%;
  position: absolute;
  width: 15%;
  z-index:2;
}

.slider::before {
  left:0;
  top:0;
}

.slider::after {
  right:0;
  top:0;
  transform: rotateZ(180deg);
}