.nosotros .nosotros-container {
  margin-top: 180px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjZmZmIj48L3JlY3Q+CjxyZWN0IHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9IiNjY2MiPjwvcmVjdD4KPC9zdmc+");
}
.nosotros .nosotros-container .container {
  display: flex;
}
.nosotros .nosotros-container .left-nosotros {
  width: 60%;
}
.nosotros .nosotros-container .left-nosotros .title {
  display: flex;
}
.nosotros .nosotros-container .left-nosotros .title h3 {
  margin: 70px 0;
  font-size: 2.5rem;
  color: #0022ff;
  font-weight: 900;
}
.nosotros .nosotros-container .left-nosotros .title .line-title {
  margin: 20px 30px 20px 0;
  height: 80% x;
  width: 3px;
  background-color: rgb(0, 255, 234);
}
.nosotros .nosotros-container .left-nosotros p {
  font-family: var(--secondary-font);
  font-size: 1rem;
  font-weight: 300;
  width: 90%;
}
.nosotros .nosotros-container .left-nosotros p strong {
  font-family: var(--secondary-font) !important;
}
.nosotros .nosotros-container .right-nosotros {
  margin: auto;
  width: 30%;
  height: 350px;
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.nosotros .nosotros-container .right-nosotros h3 {
  width: 100%;
  padding: 20px;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #ffffff;
}
.nosotros .nosotros-container .right-nosotros ul {
  font-size: 1.5rem;
  width: 90%;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: all 1s ease-out;
}
.nosotros .nosotros-container .right-nosotros ul li {
  color: #fff !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: center;
}
.nosotros .nosotros-container .right-nosotros ul li:hover {
  color: rgb(0, 68, 255) !important;
}
.nosotros .nosotros-container .banner-nosotros {
  width: 100%;
  background-color: #0022ff;
  margin: 50px 0 0;
  padding: 20px;
  font-size: 2rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}
.nosotros .nosotros-container .banner-nosotros h4 {
  font-size: 3rem;
}
.nosotros .nosotros-container .banner-nosotros .presupuesto-nosotros {
  color: #fff !important;
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  vertical-align: center;
}

/* Estilos para pantallas menores a 768px */
@media screen and (max-width: 767px) {
  .nosotros-container {
    margin-top: 120px;
  }
  .nosotros-container .right-nosotros {
    display: none !important;
  }
  .nosotros-container .left-nosotros {
    width: 100% !important;
  }
  .nosotros-container .left-nosotros .title h3 {
    margin: 40px 0;
    font-size: 2rem !important;
  }
  .nosotros-container .left-nosotros .title .line-title {
    margin: 15px 20px 15px 0;
    width: 6px;
  }
  .nosotros-container .left-nosotros p {
    font-size: 1rem;
    width: 100%;
  }
}/*# sourceMappingURL=nosotros.css.map */